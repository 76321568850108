import { Contacts, Contact, PermissionStatus } from '@capacitor-community/contacts'

interface ContactNativePermission extends PermissionStatus {
  contacts: string
}
interface ContactPermission extends ContactNativePermission {
  isGranted: boolean,
}

interface ICapacitorContact {
  getContacts: () => Promise<{contacts: Contact[]}>
  getPermission: () => Promise<ContactPermission>
}

export default class CapacitorContact implements ICapacitorContact {
  getContacts () {
    return Contacts.getContacts()
  }

  async getPermission () {
    const permission = (await Contacts.getPermissions()) as ContactNativePermission
    return { ...permission, isGranted: permission.granted || permission.contacts === 'granted' }
  }
}
